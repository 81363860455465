import React, { useState } from 'react';
import { formatDate } from '../../../utils/date';
import TimeInput from './TimeInput';
import { getDefaultStartTime, getDefaultEndTime } from '../../../utils/time';
import moment from 'moment';
import DateTimePicker from '../../DateTimePicker';
import { Calendar } from 'lucide-react';

interface ActivityFormFieldsProps {
  onFileChange: (file: File | null) => void;
  selectedDay: {
    date: string;
  };
}

const ActivityFormFields: React.FC<ActivityFormFieldsProps> = ({ onFileChange, selectedDay }) => {
  const selectedDate = formatDate(selectedDay.date, 'YYYY-MM-DD');
  const [startTime, setStartTime] = useState(getDefaultStartTime(selectedDate, true));
  const [endTime, setEndTime] = useState(getDefaultEndTime(selectedDate, true));

  const handleStartTimeChange = (newStartTime: string) => {

    setStartTime(newStartTime);

    // If end time is before start time, update end time to start time + 1 hour
    const startMoment = moment(newStartTime);
    const endMoment = moment(endTime);

   
    if (endMoment.isSameOrBefore(startMoment)) {
      const newEndMoment = startMoment.clone().add(1, 'hour');
      if (newEndMoment.hours() >= 23) {
        newEndMoment.hours(23).minutes(0);
      }
      setEndTime(newEndMoment.toISOString());
    }
  };

  const handleEndTimeChange = (newEndTime: string) => {

    const startMoment = moment.utc(startTime);
    const endMoment = moment.utc(newEndTime);

    if (endMoment.isAfter(startMoment)) {
      setEndTime(newEndTime);
    }
  };

  return (
    <>
      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Activity Name *
        </label>
        <input
          type="text"
          name="name"
          required
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>

      <div className="grid grid-cols-2 gap-4">
        <div className='flex flex-1'>
          <label className="flex flex-1 mx-auto items-center justify-start text-sm font-medium text-brand-lightgray">
            <Calendar className="countdown font-mono w-5 h-5 mr-2" /> Start *
          </label>
          <DateTimePicker
            label=""
            value={startTime}
            onChange={(value: any) => {
              handleStartTimeChange(value);
            }}
            minDate={moment().toISOString()} // Optional: restrict to future dates
            maxDate={moment().add(1, 'year').toISOString()} // Optional: restrict to within next year
            mode='time'
          />
        </div>

        <div className='flex flex-1'>

          <label className="flex flex-1 mx-auto items-center justify-center text-sm font-medium text-brand-lightgray">
            <Calendar className="countdown font-mono w-5 h-5 mr-2" /> End *
          </label>
          <DateTimePicker
            label=""
            value={endTime}
            onChange={(value: any) => {
              handleEndTimeChange(value);
            }}
            minDate={moment().toISOString()} // Optional: restrict to future dates
            maxDate={moment().add(1, 'year').toISOString()} // Optional: restrict to within next year          
            mode='time'
          />
        </div>
        {/* <TimeInput
          label="Start Time"
          name="startIsoTime"
          value={startTime}
          onChange={handleStartTimeChange}
          required
        /> */}
        {/* <TimeInput
          label="End Time"
          name="endIsoTime"
          value={endTime}
          onChange={handleEndTimeChange}
          required
        /> */}
          <input
          type="hidden"
          name="startIsoTime"
          value={startTime}
        />
        <input
          type="hidden"
          name="endIsoTime"
          value={endTime}
        />
        <input
          type="hidden"
          name="startDate"
          value={selectedDate}
        />
        <input
          type="hidden"
          name="endDate"
          value={selectedDate}
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Subtitle
        </label>
        <input
          type="text"
          name="subtitle"
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Description *
        </label>
        <textarea
          name="description"
          required
          rows={3}
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Location URL
        </label>
        <input
          type="url"
          name="location"
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>

      <div>
        <label className="block text-sm font-medium text-brand-lightgray mb-1 text-left">
          Cover Image
        </label>
        <input
          type="file"
          accept="image/*"
          onChange={(e) => onFileChange(e.target.files?.[0] || null)}
          className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
        />
      </div>
    </>
  );
};

export default ActivityFormFields;