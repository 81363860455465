import React, { useContext, useEffect, useState } from 'react';
import { AlertTriangle, Lock, Unlock, X } from 'lucide-react';
import { useQueryClient } from '@tanstack/react-query';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { ToastContext } from '../Contexts';
import { useNavigate, useParams } from 'react-router-dom';

interface EventSettingsDrawerProps {
  onClose: () => void;
  eventId?: string;
}

const EventSettingsDrawer: React.FC<EventSettingsDrawerProps> = ({ onClose, eventId }) => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const { tab, schedule, subview } = useParams<{ eventId: string, subview: string, tab: string, schedule: string }>();
  
  const toast = useContext(ToastContext);
  const { data: event, isLoading }: any = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId);
  const updateEventMutation = QuerySrvc.MUTATIONS.EVENT.UPDATE_EVENT(queryClient);
  const deleteEventMutation = QuerySrvc.MUTATIONS.EVENT.DELETE_EVENT(queryClient);

  const [name, setName] = useState('');
  const [subtitle, setSubtitle] = useState('');
  const [description, setDescription] = useState('');
  const [isPrivate, setIsPrivate] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);

  useEffect(() => {
    if (event) {
      setName(event.name || '');
      setSubtitle(event.subtitle || '');
      setDescription(event.description || '');
      setIsPrivate(event.privacy === 'PRIVATE');
    }
  }, [event]);

  const handleUpdate = (field: string, value: any) => {
    if (!eventId) return;

    updateEventMutation.mutate({
      eventId,
      payload: { [field]: value }
    }, {
      onSuccess: () => {
        toast?.showSuccessToast('Event updated successfully. ');
      },
      onError: () => {
        toast?.showErrorToast('Failed to update event. ');
      }
    });
  };

  const handleDelete = () => {
    if (!eventId) return;

    deleteEventMutation.mutate(eventId, {
      onSuccess: () => {
        toast?.showSuccessToast('Event deleted successfully');
        navigate(`/manage/${tab}/${schedule}`);
      },
      onError: () => {
        toast?.showErrorToast('Failed to delete event');
      }
    });
  };

  if (isLoading) {
    return (
      <div className="h-full p-4 flex items-center justify-center">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
      </div>
    );
  }

  return (
    <div className="h-full p-4">
      <button
        onClick={onClose}
        className="absolute right-0 top-2 text-brand-lightgraybackup p-4 hover:text-brand-lightgray/80 transition-colors"
      >
        <X className="w-5 h-5" />
      </button>

      <div className="flex justify-between items-center mb-6">
        <h2 className="text-xl font-semibold text-brand-lightgraybackup">Event Settings</h2>
      </div>

      <div className="space-y-6">
        {/* Privacy Toggle */}
        <div className="flex items-center justify-between p-4 bg-brand-fadegray/20 rounded-lg">
          <div className="flex items-center gap-3">
            {isPrivate ? <Lock className="w-5 h-5 text-brand-midgreen" /> : <Unlock className="w-5 h-5 text-brand-gold" />}
            <div>
              <p className={`font-medium ${isPrivate ? `text-brand-midgreen` : `text-brand-gold`}`}>Privacy</p>
              <p className={`text-sm ${isPrivate ? `text-brand-midgreen` : `text-brand-gold`}`}>
                {isPrivate ? 'Private - Guests can view' : 'Public - Anyone can view'}
              </p>
            </div>
          </div>
          <label className="relative inline-flex items-center cursor-pointer">
            <input
              type="checkbox"
              className="sr-only peer"
              checked={isPrivate}
              onChange={(e) => {
                setIsPrivate(e.target.checked);
                handleUpdate('privacy', e.target.checked ? 'PRIVATE' : 'PUBLIC');
              }}
            />
            <div className={`w-11 h-6 bg-brand-fadegray rounded-full peer peer-checked:after:translate-x-full 
              after:content-[''] after:absolute after:top-0.5 after:left-[2px]  ${isPrivate ? `after:bg-brand-midgreen` : `after:bg-brand-gold`}
              after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-brand-lightgray/20`}></div>
          </label>
        </div>

        {/* Event Details */}
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium mb-1">Event Name</label>
            <input
              type="text"
              value={name}
              onKeyDown={(e) => {
                if (e.key === 'Enter' && !e.shiftKey) {
                  e.preventDefault();
                  e.currentTarget.blur();
                }
              }}
              onChange={(e) => setName(e.target.value)}
              onBlur={() => name !== event.name && handleUpdate('name', name)}
              className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
            />
          </div>

          {/* <div>
            <label className="block text-sm font-medium mb-1">Subtitle</label>
            <input
              type="text"
              value={subtitle}
              onChange={(e) => setSubtitle(e.target.value)}
              onBlur={() => subtitle !== event.subtitle && handleUpdate('subtitle', subtitle)}
              className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
            />
          </div>

          <div>
            <label className="block text-sm font-medium mb-1">Description</label>
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              onBlur={() => description !== event.description && handleUpdate('description', description)}
              rows={4}
              className="w-full p-2 bg-brand-fadegray/20 border border-brand-fadegray rounded-lg text-brand-lightgray"
            />
          </div> */}
        </div>

        {/* Delete Event */}
        <div className="flex flex-1 pt-6 border-t border-brand-fadegray">
          {!showDeleteConfirm ? (
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="mx-auto cursor-pointer px-4 py-2 text-brand-danger"
            >
              Delete Event
            </button>
          ) : (
            <div className="space-y-4">
              <div className="flex items-center gap-2 text-brand-danger">
                <AlertTriangle className="w-5 h-5" />
                <p className="text-sm font-normal">Are you sure you want to delete this event?</p>
              </div>
              <div className="flex gap-2">
                <button
                  onClick={handleDelete}
                  className="flex-1 px-4 py-2 border-brand-danger text-brand-danger rounded hover:bg-brand-danger/90 hover:text-brand-darkgray"
                >
                  Yes, Delete
                </button>
                <button
                  onClick={() => setShowDeleteConfirm(false)}
                  className="flex-1 px-4 py-2 border-brand-fadegray text-brand-lightgray rounded hover:bg-brand-fadegray/20"
                >
                  Cancel
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default EventSettingsDrawer;