import React, { useState, useContext, useEffect } from 'react';
import { Clock, Plus } from 'lucide-react';
import { AnimatePresence } from 'framer-motion';
import moment from 'moment';
import { useQueryClient } from '@tanstack/react-query';
import ActivityCard from './ActivityCard';
import { EventViewMode } from '../events/EventView';
import AddActivityForm from './AddActivityForm';
import { ToastContext } from '../Contexts';
import QuerySrvc from '../../services/queries/QuerySrvc';
import FloatingActionButton from '../FloatingActionButton';
import { useParams } from 'react-router-dom';
import { set } from 'lodash';

interface ActivityListProps {
  activities: any[];
  selectedActivityId: string | null;
  onElementClick: (activity: any) => void;
  mode: EventViewMode;
  selectedDay: {
    date: string;
    id: string;
    eventId: string;
  };
}

export function ActivityList({
  activities,
  onElementClick,
  selectedActivityId,
  mode,
  selectedDay
}: ActivityListProps) {
  const [showAddForm, setShowAddForm] = useState(false);
  const queryClient = useQueryClient();
  const toast = useContext(ToastContext);
  const { eventId } = useParams<{ eventId: string }>();
  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId) as any;
  const createActivityMutation = QuerySrvc.MUTATIONS.ACTIVITY.CREATE_ACTIVITY(queryClient);
  const groupedActivities: any = {};
  const isHost = event?.isOwner || event?.isHost;


  useEffect(() => {
    if (selectedDay) {
      setShowAddForm(false);
    }

  }, [selectedDay]);

  activities.sort((a: any, b: any) => {
    return moment(a.startDateTime).diff(moment(b.startDateTime))
  }).forEach((activity: any) => {
    if (!activity) {
      return;
    }

    const hour = moment.utc(activity.startDateTime).format('hh:mm A');
    if (!groupedActivities[hour]) {
      groupedActivities[hour] = [];
    }
    groupedActivities[hour].push(activity);
  });

  const sortedHourKeys = Object.keys(groupedActivities).sort((a, b) => {
    return moment(a, 'hh:mm A').diff(moment(b, 'hh:mm A'));
  });

  const handleFormSubmit = async (formData: FormData) => {
    try {
      await createActivityMutation.mutateAsync({
        eventId: selectedDay.eventId,
        dayId: selectedDay.id,
        formData
      });
      toast?.showSuccessToast('Activity created successfully');
      setShowAddForm(false);
    } catch (error: any) {
      toast?.showErrorToast(error?.response?.data?.error || 'Failed to create activity');
    }
  };

  if (activities.length === 0) {
    return (
      <div className={`flex flex-1 flex-col ${showAddForm ? '' : 'items-center justify-center h-64'} text-center`}>
        {mode === EventViewMode.MANAGE ? (
          <>

            {showAddForm &&
              <AddActivityForm
                onClose={() => setShowAddForm(false)}
                submitSuccessCallback={(response) => {
                  setShowAddForm(false);
                }}
                submitErrorCallback={(error) => { }}
                selectedDay={selectedDay}
              />
              || <button
                onClick={() => setShowAddForm(true)}
                className="flex items-center gap-2 px-6 py-3 bg-brand-lightgray text-brand-darkgray rounded-lg hover:bg-brand-lightgray/90 transition-colors"
              >
                <Plus className="w-5 h-5" />
                Add Activity
              </button>}
          </>
        ) : (
          <>
            <Clock className="w-12 h-12 text-brand-lightgraybackup mb-4" />
            <p className="text-brand-lightgraybackup">
              No activities scheduled for this day yet.
              <br />
              Take some time to relax and explore!
            </p>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="relative flex flex-1">
      {
        showAddForm &&
        <AddActivityForm
          onClose={() => setShowAddForm(false)}
          submitSuccessCallback={(response) => {
            setShowAddForm(false);
          }}
          submitErrorCallback={(error) => { }}
          selectedDay={selectedDay}
        />
        ||
        <AnimatePresence mode="wait">
          <div className="flex flex-1 flex-col space-y-8">
            {sortedHourKeys.map((hour, index) => {
              if (!groupedActivities[hour]) {
                return null;
              }

              groupedActivities[hour] = groupedActivities[hour].sort((a: any, b: any) => {
                return moment(a.startDateTime).diff(moment(b.startDateTime))
              });

              const [time, meridiem] = hour.split(" ");

              return (
                <div
                  key={hour + '' + index}
                  className={`flex flex-1 max-w-[100vw] flex-row space-y-4 relative overflow-hidden`}
                >
                  <div className='flex flex-row justify-between'>
                    <pre className="flex flex-col font-bold font-sans justify-start mt-8 items-center mx-4 sm:mx-0 sm:mr-4 text-lg text-brand-lightgray">
                      <div className="text-lg">{time}</div>
                      <div className="text-xl">{` ${meridiem}`}</div>
                      <div className='flex flex-row items-start justify-end leading-3'></div>
                    </pre>
                  </div>

                  <div className={`flex grow relative h-40 sm:h-60 overflow-y-hidden space-x-4 custom-scroll items-baseline`}>
                    {groupedActivities[hour].map((activity: any) => (
                      <ActivityCard
                        key={activity.id}
                        activity={activity}
                        selectedActivityId={selectedActivityId}
                        onElementClick={onElementClick}
                        mode={mode}
                      />
                    ))}
                  </div>
                </div>
              )
            })}
          </div>
        </AnimatePresence>
      }
      {
        isHost && mode === EventViewMode.MANAGE &&
        <FloatingActionButton text="Activity" onClick={() => setShowAddForm(true)} visible={!showAddForm} className={`bottom-4 right-6 shadow-gold `} />
      }
    </div>
  );
}