import { end } from "@popperjs/core";
import moment from "moment";

export const getErrorStringFromObject = (error: any) => {
  return (error?.response?.data?.error) || error?.error?.data?.error || error?.error?.data || error?.error?.error || error?.error?.toString();
}

export const getProfilePic = (pic: any) => {
  return typeof pic === 'string' && pic || (pic?.thumbnail || pic?.original || pic?.url) || "";
}

export const getUserNameOrEmail = (user: any) => {
  if (!user) return undefined;
  const { firstName, lastName, email, emailId } = user;
  if (firstName && lastName) return `${firstName.trim()} ${lastName.trim()}`.trim();
  return (firstName || email || emailId)?.trim();
}

export const delay = (ms: number) => {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export const getFileAsFormData = (file: any) => {
  try {
    const formData = new FormData();
    // @ts-ignore
    if (file) {
      formData.append('file', file);
    }
    return formData;
  } catch (err) {
    throw err;
  }
}

export const getFormattedDuration = (startUtcString: string, endUtcString: string) => {
  return `${Math.floor(moment.duration(moment.utc(endUtcString).diff(moment.utc(startUtcString))).asHours()) > 0 ?
    `${Math.floor(moment.duration(moment.utc(endUtcString).diff(moment.utc(startUtcString))).asHours())}h ` : ''}
                        ${moment.duration(moment.utc(endUtcString).diff(moment.utc(startUtcString))).minutes() > 0 ?
      `${moment.duration(moment.utc(endUtcString).diff(moment.utc(startUtcString))).minutes()}m` : ''}`
}

export const loadScript = (src: string) => {
  return new Promise((resolve, reject) => {
    const scriptTag = document.querySelector(`script[src="${src}"]`);

    if (scriptTag) {
      document.body.removeChild(scriptTag);
    }

    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = src;
    script.async = true;
    script.defer = true;
    script.onload = () => {
      resolve(true);
    };
    script.onerror = (err) => {
      reject(new Error(`Failed to load script: ${src}`));
    };

    document.body.appendChild(script);
  });
};
