import React, { useEffect, useState } from 'react';
import { X, Lock, Unlock, Users, UserPlus } from 'lucide-react';
import EventFormFields from './EventFormFields';
import { createEventFormData } from '../../../utils/event';
import QuerySrvc from '../../../services/queries/QuerySrvc';
import { useQueryClient } from '@tanstack/react-query';
import { getErrorStringFromObject } from '../../../services/utils';

enum RSVP_TYPE {
  OPEN = "OPEN",
  OPEN_INVITE = "OPEN_INVITE",
  INVITE_ONLY = "INVITE_ONLY"
}

interface AddEventFormProps {
  onClose: () => void;
  submitSuccessCallback?: (response: any) => void;
  submitErrorCallback?: (error: any) => void;
}

const AddEventForm: React.FC<AddEventFormProps> = ({ onClose, submitSuccessCallback, submitErrorCallback }) => {
  const queryClient = useQueryClient();
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [rsvpType, setRsvpType] = useState<RSVP_TYPE>(RSVP_TYPE.OPEN_INVITE);
  const createEventMutation = QuerySrvc.MUTATIONS.EVENT.CREATE_EVENT(queryClient);

  useEffect(() => {
    if (createEventMutation.status === 'success') {
      submitSuccessCallback && submitSuccessCallback(createEventMutation.data);
    } else if (createEventMutation.status === 'error') {
      setError(getErrorStringFromObject(createEventMutation.error));
      submitErrorCallback && submitErrorCallback(createEventMutation.error);
    }
  }, [createEventMutation.status]);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setError(null);

    const result = createEventFormData(e.currentTarget as HTMLFormElement, selectedFile);

    if ('error' in result) {
      setError(result.error);
      return;
    }

    // Add RSVP type to form data
    result.append('rsvpType', rsvpType);
    handleFormSubmit(result);
  };

  const handleFormSubmit = async (formData: FormData) => {
    try {
      createEventMutation.mutate(formData);
    } catch (error: any) {
      console.error('Failed to create event:', error);
    }
  };

  const getRsvpTypeIcon = (type: RSVP_TYPE) => {
    switch (type) {
      case RSVP_TYPE.OPEN:
        return <Unlock className="w-4 h-4" />;
      case RSVP_TYPE.OPEN_INVITE:
        return <UserPlus className="w-4 h-4" />;
      case RSVP_TYPE.INVITE_ONLY:
        return <Lock className="w-4 h-4" />;
    }
  };

  const getRsvpTypeDescription = (type: RSVP_TYPE) => {
    switch (type) {
      case RSVP_TYPE.OPEN:
        return "Anyone can join using the link without approval.";
      case RSVP_TYPE.OPEN_INVITE:
        return "Anyone can request to join using the link. Requires host approval.";
      case RSVP_TYPE.INVITE_ONLY:
        return "Only guests invited by a host can join.";
    }
  };

  return (
    <div className="fixed inset-0 bg-brand-darkgray/90 flex items-center justify-center p-4 z-50">
      <div className="relative flex flex-col bg-brand-darkgray border border-brand-fadegray rounded-lg w-full max-w-lg max-h-[90vh]">
        {/* Header */}
        <div className="flex justify-between items-center p-4 border-b border-brand-fadegray">
          <h2 className="text-xl font-semibold text-brand-lightgray">Create New Event</h2>
          <button
            onClick={onClose}
            className="text-brand-lightgray hover:text-brand-lightgray/80"
          >
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Error Message */}
        {error && (
          <div className="px-4 py-2 m-4 bg-red-500/10 border border-red-500 rounded text-red-500">
            {error}
          </div>
        )}

        {/* Form */}
        <form onSubmit={handleSubmit} className="flex flex-col flex-1 overflow-hidden">
          {/* Scrollable Content */}
          <div className="flex-1 overflow-y-auto p-4 custom-scroll">
            <EventFormFields onFileChange={setSelectedFile} />

            {/* RSVP Type Selection */}
            <div className="mt-6">
              <label className="block text-sm font-medium text-brand-lightgray mb-2">
                RSVP Type *
              </label>
              <div className="grid grid-cols-3 gap-2">
                {Object.values(RSVP_TYPE).map((type) => (
                  <label
                    key={type}
                    className={`relative flex flex-row justify-center items-center p-2 rounded-lg cursor-pointer border transition-colors text-center
                      ${rsvpType === type
                        ? 'border-brand-lightgray bg-brand-lightgray/10'
                        : 'border-brand-fadegray bg-brand-fadegray/20'
                      } hover:bg-brand-lightgray/5`}
                    title={getRsvpTypeDescription(type)}
                  >
                    <input
                      type="radio"
                      name="rsvpType"
                      value={type}
                      checked={rsvpType === type}
                      onChange={(e) => setRsvpType(e.target.value as RSVP_TYPE)}
                      className="hidden"
                    />
                    {getRsvpTypeIcon(type)}
                    <span className="text-xs text-brand-lightgray font-medium mt-1 ml-2">
                      {type.replace('_', ' ')}
                    </span>
                  </label>
                ))}
              </div>
              <p className="text-sm text-brand-lightgraybackup mt-2">
                {getRsvpTypeDescription(rsvpType)}
              </p>
            </div>
          </div>

          {/* Footer Actions */}
          <div className="flex justify-end gap-4 p-4 border-t border-brand-fadegray">
            <button
              type="button"
              onClick={onClose}
              className="px-4 py-2 border border-brand-fadegray text-brand-lightgray rounded-lg hover:bg-brand-fadegray/20"
              disabled={createEventMutation.isPending}
            >
              Cancel
            </button>
            <button
              type="submit"
              className="px-4 py-2 bg-brand-lightgray text-brand-darkgray rounded-lg w-40
                enabled:hover:bg-brand-lightgray/90 disabled:opacity-50 disabled:text-brand-fadegray"
              disabled={createEventMutation.isPending}
            >
              {
                createEventMutation.isPending && 
                <span className="loading loading-bars loading-xs"></span> ||
                <span>
                  Create Event
                </span>
              }
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AddEventForm;