import axios from "axios";
import CONSTANTS from "../config/constants";
import StorageSrvc from "./StorageSrvc";
import { STORAGE_SRVC_KEYS } from "../config/constants/AppStrings";
import { getErrorStringFromObject } from "./utils";
import { AUTH_CONSTANTS } from "./queries/AuthApi";

class HttpSrvc {
  baseURL: string;
  axiosInstance: any;
  timeout!: number;
  isRefreshing: boolean = false;
  failedQueue: any[] = [];

  constructor(baseUrl: string, timeout: number) {
    this.baseURL = baseUrl;
    this.axiosInstance = axios.create({
      baseURL: this.baseURL,
      timeout: timeout
    });

    this.axiosInstance.interceptors.request.use(
      (config: any) => this.getHeaderConfig(config),
      function (error: any) {

      });

    this.axiosInstance.interceptors.response.use(
      (response: any) => response,
      async (error: any) => {
        const originalRequest = error.config;

        // If error is 401 and we haven't tried refreshing yet
        // if (AUTH_CONSTANTS.REFRESH_USER_TOKEN_API !== originalRequest.url && error?.response?.status === 401 && !originalRequest._retry) {

        if (AUTH_CONSTANTS.REFRESH_USER_TOKEN_API !== originalRequest.url && error?.response?.status === 401 && !originalRequest._retry) {

          if (this.isRefreshing) {
            // If already refreshing, queue this request
            return new Promise((resolve, reject) => {
              this.failedQueue.push({ resolve, reject });
            })
              .then(token => {
                originalRequest.headers['Authorization'] = `Bearer ${token}`;
                return this.axiosInstance(originalRequest);
              })
              .catch(err => Promise.reject(err));
          }

          originalRequest._retry = true;
          this.isRefreshing = true;

          try {
            // Get refresh token
            const refreshToken = await StorageSrvc.getItem(STORAGE_SRVC_KEYS.REFRESH_TOKEN);
            if (!refreshToken) {

              throw new Error(getErrorStringFromObject(error) || 'No refresh token available');
            }

            // Call refresh token API
            const response = await this.axiosInstance.get('/auth/refresh-user-token', {
              headers: {
                'RefreshToken': `Bearer ${refreshToken}`
              }
            });

            if (response?.data?.authToken) {
              // Store new tokens
              await StorageSrvc.setItem(STORAGE_SRVC_KEYS.AUTH_TOKEN, response.data.authToken);
              if (response.data.refreshToken) {
                await StorageSrvc.setItem(STORAGE_SRVC_KEYS.REFRESH_TOKEN, response.data.refreshToken);
              }

              // Process queued requests
              this.failedQueue.forEach(prom => {
                prom.resolve(response.data.authToken);
              });

              // Update current request and retry
              originalRequest.headers['Authorization'] = `Bearer ${response.data.authToken}`;
              return this.axiosInstance(originalRequest);
            }
          } catch (refreshError) {
            // Process queued requests with error
            this.failedQueue.forEach(prom => {
              prom.reject(refreshError);
            });

            // Clear storage and reject
            StorageSrvc.clear();
            return Promise.reject(refreshError);
          } finally {
            this.failedQueue = [];
            this.isRefreshing = false;
          }
        }

        return Promise.reject(error);
      }
    );
  }

  private async getHeaderConfig(headerConfig: any) {
    const authToken = await StorageSrvc.getItem(STORAGE_SRVC_KEYS.AUTH_TOKEN);
    if (authToken) {
      headerConfig.headers["Authorization"] = `Bearer ${authToken}`;
    }
    return headerConfig;
  };

  private errorMessageHandler(error: any) {
    if (error.code === "ECONNABORTED") {
      throw Error("Network Connection Timeout");
    }
    throw error;
  }

  public async postFormData(path: string, data: any, queryParams?: any, headers?: any) {
    try {
      const config = {
        method: "post",
        url: this.baseURL + path,
        params: queryParams,
        headers: { ...headers, "Content-Type": "multipart/form-data" },
        data: data
      };
      const response = await this.axiosInstance.request(config);
      return response.data;
    }
    catch (err: any) {
      this.errorMessageHandler(err);
    }
  }

  public async post(path: string, data: any, queryParams?: any, headers?: any) {
    try {
      const config = {
        method: "post",
        url: path,
        params: queryParams,
        headers,
        data
      };
      const response = await this.axiosInstance.request(config);
      return response.data;
    }
    catch (err: any) {
      this.errorMessageHandler(err);
    }
  }

  public async put(path: string, data: any, queryParams?: any, headers?: any) {
    try {
      const config = {
        method: "put",
        url: path,
        params: queryParams,
        headers,
        data
      };
      const response = await this.axiosInstance.request(config);
      return response.data;
    }
    catch (err: any) {
      this.errorMessageHandler(err);
    }
  }

  public async get(path: string, queryParams?: any, headers?: any) {
    try {
      const response = await this.axiosInstance.request(
        {
          method: "get",
          url: path,
          params: queryParams,
          headers,
        }
      );
      return response.data
    }
    catch (err) {
      this.errorMessageHandler(err);
    }
  }

  public async delete(path: string, data?: any, queryParams?: any, headers?: any) {
    try {
      const response = await this.axiosInstance.request(
        {
          method: "delete",
          url: path,
          params: queryParams,
          headers,
          data
        }
      );
      return response.data
    }
    catch (err) {
      this.errorMessageHandler(err);
    }
  }
}

export default new HttpSrvc(CONSTANTS.ENV_CONSTANTS.BASE_URL, CONSTANTS.ENV_CONSTANTS.HTTP_TIMEOUT);