import React, { useState, useMemo, Fragment, useEffect, useContext } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import ApiService from '../../../utils/api';
import { Attendee, AttendeesResponse, ATTENDEE_STATUS, AttendeeStatus, RSVP_STATUS } from './types';
import { filterAttendeesByStatus, filterAttendeesBySearch, filterAttendeesByTag, extractUniqueTags } from './utils';
import AttendeeCard from './AttendeeCard';
import EmptyState from './EmptyState';
import AttendeeFilters from './AttendeeFilters';
import FilterModal from './FilterModal';
import FloatingActionButton from '../../FloatingActionButton';
import QuerySrvc from '../../../services/queries/QuerySrvc';
import { color } from 'framer-motion';
import { EllipsisVertical, Plus, Share2 } from 'lucide-react';
import { CurrentUserContext, ToastContext } from '../../Contexts';
import { ATTENDEE_RSVP_STATUS } from './Attendee.Constants';

interface AttendeesListProps {
  eventId: string;
  onHeaderActionClick: (buttonId: string) => void;
}

const AttendeesList: React.FC<AttendeesListProps> = ({ eventId, onHeaderActionClick }) => {
  const queryClient = useQueryClient();

  const [searchText, setSearchText] = useState('');
  const [selectedTag, setSelectedTag] = useState<string | null>(null);
  const [showFilters, setShowFilters] = useState(false);
  const [rsvpGroupSelected, setRsvpGroupSelected] = useState('ALL');
  const toast = useContext(ToastContext);
  const { currentUser } = useContext(CurrentUserContext);
  const [attendees, setAttendees] = useState<any[]>([]);

  const { data: rsvpAttendees, isLoading, isSuccess: isRsvpStatusFetchSuccess, isError: isRsvpStatusFetchError,
    isFetching: isRsvpStatusFetching, refetch: refetchRsvpStatus } = QuerySrvc.QUERIES.RSVP.GET_EVENT_RSVP(eventId);

  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId) as any;

  const { isOwner, isHost, isGuest } = event || {};

  const rsvpReminderMutation = QuerySrvc.MUTATIONS.RSVP.SEND_REMINDER(queryClient);

  const removeAttendeesMutation = QuerySrvc.MUTATIONS.EVENT.REMOVE_GUEST(queryClient);

  const makeHostMutation = QuerySrvc.MUTATIONS.EVENT.MAKE_HOST(queryClient);

  const removeHostMutation = QuerySrvc.MUTATIONS.EVENT.REMOVE_HOST(queryClient);

  const rsvpUpdateByHostMutation = QuerySrvc.MUTATIONS.RSVP.RSVP_UPDATE_BY_HOST(queryClient);

  useEffect(() => {
    if (isRsvpStatusFetchSuccess) {
      setAttendees(rsvpAttendees);
    }
  }, [isRsvpStatusFetching])

  useEffect(() => {
    if (rsvpReminderMutation.status === 'success') {
      toast?.showSuccessToast('Reminder Sent To Pending RSVPs!');

    } else if (rsvpReminderMutation.status === 'error') {
      toast?.showErrorToast('Failed to send reminder');
    }
  }, [rsvpReminderMutation.status]);

  const tags = useMemo(() => {
    if (!attendees) return [];
    return extractUniqueTags(attendees);
  }, [attendees]);

  let filteredAttendees = attendees;


  if (selectedTag) {
    filteredAttendees = filteredAttendees.filter((attendee: any) => {
      const user = attendee?.user;
      return user?.tags?.includes(selectedTag);
    });
  }

  if (searchText) {
    filteredAttendees = filteredAttendees.filter((attendee: any) => {
      const user = attendee?.user;
      return ((user?.firstName + ' ' + user?.lastName + ' ' + user.emailId + ' ' + user.organisation).toLowerCase().includes(searchText.toLowerCase()));
    });
  }

  if (isLoading) {
    return (
      <div className="flex justify-center items-center py-8">
        <div className="animate-spin rounded-full h-8 w-8 border-t-2 border-b-2 border-brand-lightgray"></div>
      </div>
    );
  }


  const rsvpGroups = [
    {
      status: ATTENDEE_RSVP_STATUS.ALL,
      attendees: filteredAttendees,
      color: 'text-brand-lightgray'
    },
    ...(isHost ? [{
      status: ATTENDEE_RSVP_STATUS.ATTENDING,
      attendees: filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.YES),
      color: 'text-brand-midgreen',
      dropdown: filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.YES).some(attendee => attendee.checked) ? [
        {
          label: 'Remove Attendee',
          onClick: () => {
            const removalList = (filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.YES).filter(attendee => attendee.checked).map((rsvp: any) => rsvp?.user?.guid));
            if (window.confirm(`Are you sure you want to remove ${removalList.length} attendee(s)?`)) {
              removeAttendeesMutation.mutate({ eventId, guestIds: removalList });
            }
          }
        },
        {
          label: 'Make Host',
          onClick: () => {
            const hostlist = (filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.YES).filter(attendee => attendee.checked).map((rsvp: any) => rsvp?.user?.guid));
            makeHostMutation.mutate({ eventId, users: hostlist });
          }
        },
        {
          label: 'Remove as Host',
          onClick: () => {
            const hostlist = (filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.YES).filter(attendee => attendee.checked).map((rsvp: any) => rsvp?.user?.guid));
            removeHostMutation.mutate({ eventId, users: hostlist });
          }
        }
      ] : undefined
    },
    {
      status: ATTENDEE_RSVP_STATUS.PENDING,
      attendees: [
        ...filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.REQUESTED),
        ...filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.ONBOARD_REQUESTED)
      ],
      color: 'text-brand-gold',
      dropdown: [
        ...filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.REQUESTED),
        ...filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.ONBOARD_REQUESTED)
      ].length ? [
        {
          label: 'Send Reminder',
          onClick: () => rsvpReminderMutation.mutate({ eventId })
        }
      ] : undefined
    },
    {
      status: ATTENDEE_RSVP_STATUS.HOST_APPROVAL_REQUESTED,
      attendees: [
        ...filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.HOST_APPROVAL_REQUESTED),
      ],
      color: 'text-brand-gold',
      dropdown: filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.HOST_APPROVAL_REQUESTED).some(attendee => attendee.checked) ? [
        {
          label: 'Approve',
          onClick: () => {
            const approvalList = (filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.HOST_APPROVAL_REQUESTED).filter(attendee => attendee.checked).map((rsvp: any) => rsvp.id));
            rsvpUpdateByHostMutation.mutate({ payload: { eventId, status: RSVP_STATUS.YES, rsvps: approvalList } });
          }
        },
        {
          label: 'Reject',
          onClick: () => {
            const rejectionList = (filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.HOST_APPROVAL_REQUESTED).filter(attendee => attendee.checked).map((rsvp: any) => rsvp.id));
            rsvpUpdateByHostMutation.mutate({ payload: { eventId, status: RSVP_STATUS.NO, rsvps: rejectionList } });
          }
        }
      ] : undefined
    },
    {
      status: ATTENDEE_RSVP_STATUS.DECLINED,
      attendees: [
        ...filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.NO),
        ...filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.HOST_REMOVED),
        ...filterAttendeesByStatus(filteredAttendees, RSVP_STATUS.HOST_APPROVAL_DENIED)
      ],
      color: 'text-brand-danger',
      dropdown: filterAttendeesByStatus(filteredAttendees, [RSVP_STATUS.HOST_APPROVAL_DENIED, RSVP_STATUS.HOST_REMOVED, RSVP_STATUS.NO]).some(attendee => attendee.checked) ? [
        {
          label: 'Resend Invite',
          onClick: () => {
            const approvalList = (filterAttendeesByStatus(filteredAttendees, [RSVP_STATUS.HOST_APPROVAL_DENIED, RSVP_STATUS.HOST_REMOVED, RSVP_STATUS.NO])
              .filter(attendee => attendee.checked).map((rsvp: any) => rsvp.id));
            rsvpUpdateByHostMutation.mutate({ payload: { eventId, status: RSVP_STATUS.REQUESTED, rsvps: approvalList } });
          }
        }
      ] : undefined
    }
    ] : [])
  ];

  const onAttendeeCheck = (attendee: Attendee, groupStatus: string) => {
    const updatedAttendees = attendees.map((att) => {
      if (att.id === attendee.id) {
        return { ...att, checked: !att.checked };
      }
      return att;
    });
    setAttendees(updatedAttendees);
  }

  return (
    <div className='flex flex-1 flex-col h-full overflow-hidden'>

      <div className="flex justify-between flex-col sm:flex-row">

        <FilterModal
          isOpen={showFilters}
          onClose={() => setShowFilters(false)}
          tags={tags}
          selectedTag={selectedTag}
          onTagSelect={setSelectedTag}
        />
        <div className="p-4">
          <AttendeeFilters
            searchText={searchText}
            onSearchChange={setSearchText}
            selectedTag={selectedTag}
            onTagSelect={setSelectedTag}
            tags={tags}
            onShowFiltersClick={() => setShowFilters(true)}
          />
        </div>
        {(isOwner || isHost) &&
          <div className='flex items-center pb-4 sm:py-0 px-4 space-x-4 justify-items-center justify-between sm:justify-end'>
            <button
              onClick={async () => {
                onHeaderActionClick('share');
              }}
              className={`flex items-center justify-center gap-4 text-brand-lightgray py-2 rounded w-28 border-brand-fadegray border-2 hover:shadow-lightgray transition-all
                      bg-gradient-to-r from-brand-darkgray via-brand-lightgray/10 to-brand-darkgray`} >
              <Share2 className='w-4 h-4' /> Share
            </button>
            <button
              onClick={async () => {
                onHeaderActionClick('invite');
              }}
              className={`flex items-center justify-center gap-4 text-brand-lightgray py-2 rounded w-28 border-brand-fadegray border-2 hover:shadow-lightgray transition-all
                      bg-gradient-to-r from-brand-darkgray via-brand-lightgray/10 to-brand-darkgray`} >
              <Plus className='w-4 h-4' /> Invite
            </button>
          </div> || null}

      </div>
      <div className=''>
        <div className='sm:hidden flex divider w-40 mx-auto my-0 h-1 mb-2'></div>
        <div className="sm:hidden flex flex-1 justify-between p-4 join gap-4 flex-wrap">
          {
            rsvpGroups.map((group, index) =>
              <button className={`join-item ${rsvpGroupSelected === group.status ? group.color : 'text-brand-lightgraybackup'}`}
                onClick={() => setRsvpGroupSelected(group.status)} key={index}
              >
                {group.status.replace("_", " ")} {group.attendees?.length ? `(${group.attendees?.length})` : ''}
              </button>
            )
          }
        </div>
      </div>
      <div className="flex flex-1 flex-row overflow-hidden">
        {
          rsvpGroups[0].attendees?.length && rsvpGroups.map((group, index) => (
            <Fragment key={index}>
              <div className={`sm:flex flex-1 flex-col overflow-hidden ${rsvpGroupSelected === group.status ? 'flex' : 'hidden'}`}>
                {
                  isHost && <div className="flex flex-col">
                    <div className="hidden sm:flex items-center justify-between py-4 border-b border-brand-fadegray">
                      <div className="flex flex-1 relative items-center space-x-4 justify-between">
                        <p className={`${group.color} mx-auto`}>
                          {group.status.replaceAll("_", " ")} {group.attendees?.length ? `(${group.attendees?.length})` : ''}
                        </p>
                        {
                          group.dropdown && <div className="absolute right-0 ml-auto h-auto">
                            <div className="dropdown dropdown-end">
                              <div tabIndex={0} role="button" className="btn btn-ghost pr-0 border-0 hover:bg-brand-fadegray/5">
                                <EllipsisVertical />
                              </div>
                              <ul tabIndex={0} className="menu menu-sm border dropdown-content bg-brand-darkgray rounded-box z-[1] mt-3 w-52 shadow" key={index}>
                                {
                                  group.dropdown.map((item, index) => (
                                    <li key={index} className="p-4  rounded hover:bg-brand-fadegray/20 cursor-pointer" onClick={item.onClick}>{item.label}</li>
                                  ))
                                }
                              </ul>
                            </div>
                          </div>
                        }
                      </div>
                    </div>
                  </div>
                }
                <div className="flex flex-1 flex-col overflow-y-auto custom-scroll">
                  {group.attendees?.length > 0 ? (
                    group.attendees.map((attendee: any, index: any) => (
                      <AttendeeCard
                        key={attendee.id + index}
                        attendee={attendee}
                        onMessageClick={console.log}
                        onProfileClick={console.log}
                        showIcon={group.status === 'ALL'}
                        groupStatus={group.status}
                        onCheck={onAttendeeCheck}
                        isOwner={isOwner}
                        isHost={isHost}
                        isGuest={isGuest}
                        currentUser={currentUser}
                      />
                    ))
                  ) : (
                    <div className='flex flex-1 flex-col items-center pt-4'>
                      No Guests in this list
                    </div>
                  )}
                </div>
              </div>
              {index < rsvpGroups?.length - 1 && <div className={`divider divider-horizontal hidden sm:flex`} />}
            </Fragment>

          )) ||
          <EmptyState />
        }
      </div>

    </div>
  );
};

export default AttendeesList;