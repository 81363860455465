import React, { useState, forwardRef, useImperativeHandle } from 'react';

const ModalWrapper = forwardRef((props: any, ref) => {
  const [isOpen, setIsOpen] = useState(false);

  // Expose methods to parent components through ref
  useImperativeHandle(ref, () => ({
    openModal() {
      setIsOpen(true);
    },
    closeModal() {
      setIsOpen(false);
    },
  }));

  return (
    <>
      {isOpen && (
        <div className="modal modal-open" onClick={(e) => {e.stopPropagation(); setIsOpen(false)}}>
          <div className="modal-box bg-brand-darkgray" onClick={(e) => e.stopPropagation()}>
            <h2 className="font-bold text-lg text-brand-lightgray">{props.title || 'Modal Title'}</h2>
            <p className="py-4">{props.children || 'This is the modal content'}</p>
            <div className="modal-action">
              {props.actionButton}
              <button
                className="btn btn-brand-darkgray"
                onClick={(e) => {
                  e.stopPropagation();
                  setIsOpen(false);
                }} >
                Close
              </button>
            </div>
          </div>
        </div>
      )}
    </>
  );
});

export default ModalWrapper;