import React from 'react';
import { ChevronRight } from 'lucide-react';
import { Link, useLocation, useParams } from 'react-router-dom';
import QuerySrvc from '../../services/queries/QuerySrvc';

interface BreadcrumbsProps {
  activeTab: string;
  eventName?: string;
  onBreadcrumbClick: (path: string) => void;
}

const Breadcrumbs: React.FC<BreadcrumbsProps> = ({ activeTab, onBreadcrumbClick }) => {
  const { pathname } = useLocation();

  const { tab, schedule, eventId } = useParams();

  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId) as any;

  return (
    <div className="flex w-screen sticky p-4 py-2 sm:-l-4 items-center top-0 z-40 breadcrumbs text-sm bg-brand-darkgray text-brand-lightgraybackup overflow-hidden">
      <ul className='flex flex-1 overflow-hidden'>
        <li>
          Manage
        </li>
        {
          tab && <li>
            <Link className={`capitalize ${schedule ? 'text-brand-lightgraybackup' : 'text-brand-lightgray'}`} to={`/manage/${tab}/ongoing`}>
              {tab}
            </Link>
          </li>
        }
        {
          schedule && <li>
            <Link className={`capitalize ${event?.name ? 'text-brand-lightgraybackup' : 'text-brand-lightgray'}`} to={`/manage/${tab}/${schedule}`}>
              {schedule}
            </Link>
          </li>
        }
        {
          event?.name &&
          <li className='text-brand-lightgray'>
            {event?.name}
            {event.privacy === 'PRIVATE' && <span className='text-brand-midgreen'>
              &nbsp; [ Private ]
            </span>
            }
          </li>
        }
      </ul>
    </div>
  );
};

export default Breadcrumbs;