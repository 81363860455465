import React, { useState, useEffect, useRef } from 'react';
import { MapPin, Loader2 } from 'lucide-react';
import { useDebounce } from 'use-debounce';


interface Place {
  place_id: string;
  description: string;
  structured_formatting: {
    main_text: string;
    secondary_text: string;
  };
}

interface PlaceResponse {
  structuredFormatting: {
    main_text: string;
    secondary_text: string;
  };
  placeId: string;
  description: string;
  googleMapsUri: string;
}

interface PlaceSearchProps {
  location: string | PlaceResponse;
  onPlaceSelect: (place: Place) => void;
  enabled: boolean;
}

export function PlaceSearch({ location, onPlaceSelect, enabled }: PlaceSearchProps) {
  const [input, setInput] = useState('');
  const [debouncedInput] = useDebounce(input, 500);
  const [predictions, setPredictions] = useState<Place[]>([]);
  const [loading, setLoading] = useState(false);
  const [selectedPlace, setSelectedPlace] = useState<Place | null>(null);
  const [isInitialized, setIsInitialized] = useState(false);

  const google = window.google;

  const autocompleteService = useRef<google.maps.places.AutocompleteService | null>(null);

  const initializeGoogleMaps = async () => {

    if (isInitialized) return;

    try {
      const google = window.google;

      if (!autocompleteService.current && google) {
        autocompleteService.current = new google.maps.places.AutocompleteService();
        setIsInitialized(true);
      }

    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    if (location) {
      setInput(typeof location === 'string' ? location : location.description);
    } else {
      setInput('');
      setPredictions([]);
    }
  }, [location]);

  useEffect(() => {
    if (selectedPlace) {
      onPlaceSelect(selectedPlace)
    }
    
  }, [selectedPlace]);

  useEffect(() => {
    initializeGoogleMaps();
  }, []);

  useEffect(() => {
    const searchPlaces = async () => {

      const locationString = location ?  (typeof location === 'string' ? location : location?.description): '';

      if (!debouncedInput || selectedPlace?.description === debouncedInput || locationString === debouncedInput || 
        !autocompleteService.current) return;

      setLoading(true);
      try {
        const response = await new Promise<google.maps.places.AutocompletePrediction[]>(
          (resolve, reject) => {
            autocompleteService.current?.getPlacePredictions(
              { input: debouncedInput },
              (results: any, status: any) => {
                if (status === google.maps.places.PlacesServiceStatus.OK && results) {
                  resolve(results);
                } else {
                  reject(status);
                }
              }
            );
          }
        );
        setPredictions(response as Place[]);
      } catch (error) {
        setPredictions([]);
      } finally {
        setLoading(false);
      }
    };

    searchPlaces();
  }, [debouncedInput]);

  const handleSelect = (place: Place) => {
    setSelectedPlace(place);
    setInput(place.description);
    setPredictions([]);
  };


  return (
    <div className="w-full max-w-md">
      <div className="form-control w-full">
        <div className="relative">
          <div className="relative w-full">
            {predictions.length > 0 && (
              <ul className="menu flex-nowrap custom-scroll bg-brand-darkgray w-full rounded-box shadow-lg max-h-[300px] overflow-y-auto absolute bottom-full mb-2">
                {predictions.map((place) => (
                  <li key={place.place_id}>
                    <button
                      onClick={() => handleSelect(place)}
                      className="flex items-start p-4 hover:bg-brand-fadegray"
                    >
                      <MapPin className="h-5 w-5 mt-1 mr-2 flex-shrink-0" />
                      <div>
                        <div className="font-medium">
                          {place.structured_formatting.main_text}
                        </div>
                        <div className="text-sm text-base-content/70">
                          {place.structured_formatting.secondary_text}
                        </div>
                      </div>
                    </button>
                  </li>
                ))}
              </ul>
            )}

            <input
              disabled={!enabled}
              type="text"
              placeholder="Add a location..."
              className={`input bg-transparent rounded-none focus:outline-none border-transparent	border-transparent focus:border-b-brand-fadegray border-y-2 border-0 w-full p-0
                disabled:cursor-default disabled:bg-transparent disabled:border-0 `}
              value={input}
              onChange={(e) => setInput(e.target.value)}
            />
            {loading && (
              <div className="absolute right-3 top-3">
                <Loader2 className="animate-spin h-6 w-6 text-gray-400" />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}