import { Calendar, Camera, Clock, MapPin, MapPinned, Trash2 } from 'lucide-react';
import moment from 'moment';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { EventViewMode } from '../events/EventView';
import { useQueryClient } from '@tanstack/react-query';
import { ToastContext } from '../Contexts';
import QuerySrvc from '../../services/queries/QuerySrvc';
import { Link, useParams } from 'react-router-dom';
import { formatDate } from '../../utils/date';
import { getErrorStringFromObject, getFormattedDuration } from '../../services/utils';
import DateTimePicker from '../DateTimePicker';
import { PlaceSearch } from '../PlaceSearch';

interface ActivityDetailsProps {
  activity: any;
  mode: EventViewMode;
}

const ActivityDetails = ({ activity, mode }: ActivityDetailsProps) => {
  const queryClient = useQueryClient();
  const toast = useContext(ToastContext);
  const activityNameRef = useRef<any>(null);
  const subtitleRef = useRef<any>(null);
  const locationRef = useRef<any>(null);
  const descriptionRef = useRef<any>(null);
  const { eventId } = useParams<{ eventId: string }>();
  const { data: event } = QuerySrvc.QUERIES.EVENT.EVENT_GET_BY_ID(eventId) as any;
  const isHost = event?.isOwner || event?.isHost;

  const activityStartTime = moment.utc(activity?.startDateTime).toISOString();
  const activityEndTime = moment.utc(activity?.endDateTime).toISOString();

  const updateActivityMutation = QuerySrvc.MUTATIONS.ACTIVITY.UPDATE_ACTIVITY(queryClient);
  const uploadImageMutation = QuerySrvc.MUTATIONS.ACTIVITY.UPLOAD_ACTIVITY_IMAGE(queryClient);
  const deleteActivityMutation = QuerySrvc.MUTATIONS.ACTIVITY.DELETE_ACTIVITY(queryClient);

  const fileInputRef = useRef<HTMLInputElement>(null);
  const [startTime, setStartTime] = useState<any>(null);
  const [endTime, setEndTime] = useState<any>(null);

  useEffect(() => {
    if (activityStartTime !== startTime) {
      setStartTime(activityStartTime);
    }
  }, [activityStartTime]);

  useEffect(() => {
    if (activityEndTime !== endTime) {
      setEndTime(activityEndTime);
    }
  }, [activityEndTime]);

  useEffect(() => {
    if (deleteActivityMutation.status === 'success') {
      toast?.showSuccessToast('Activity deleted successfully');
    } else if (deleteActivityMutation.status === 'error') {
      toast?.showErrorToast(getErrorStringFromObject(deleteActivityMutation.error) || 'Failed to delete activity');
    }
  }, [deleteActivityMutation.status]);

  useEffect(() => {
    if (uploadImageMutation.status === 'success') {
      toast?.showSuccessToast('Image uploaded successfully');
    } else if (uploadImageMutation.status === 'error') {
      toast?.showErrorToast('Failed to upload image');
    }
  }, [uploadImageMutation.status]);

  useEffect(() => {
    if (updateActivityMutation.status === 'success') {
      toast?.showSuccessToast('Activity updated successfully');
    } else if (updateActivityMutation.status === 'error') {
      toast?.showErrorToast(getErrorStringFromObject(updateActivityMutation.error) || 'Failed to update activity');
    }
  }, [updateActivityMutation.status]);

  useEffect(() => {
    if (subtitleRef.current && subtitleRef.current.innerText !== activity?.subtitle) {
      subtitleRef.current.innerText = activity?.subtitle ? activity?.subtitle : '';
    }
  }, [activity?.subtitle]);


  useEffect(() => {
    if (activityNameRef.current && activityNameRef.current.innerText !== activity?.name) {
      activityNameRef.current.innerText = activity?.name;
    }
  }, [activity?.name]);

  useEffect(() => {
    if (locationRef.current && locationRef.current.innerText !== activity?.location) {
      locationRef.current.innerText = activity?.location || 'Click To Add Location Url';
    }
  }, [activity?.location]);

  useEffect(() => {
    if (descriptionRef.current && descriptionRef.current.innerText !== activity?.description) {
      descriptionRef.current.innerText = activity?.description;
    }
  }, [activity?.description]);

  const handleImageUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file || !activity.eventId) return;

    uploadImageMutation.mutate({
      eventId: activity.eventId,
      dayId: activity.programId,
      activityId: activity.id,
      file
    });
    fileInputRef.current && (fileInputRef.current.value = '');
  };

  const handleDragUpload = (e: React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const file = e.dataTransfer.files?.[0];
    if (!file || !activity.eventId) return;

    uploadImageMutation.mutate({
      eventId: activity.eventId,
      dayId: activity.programId,
      activityId: activity.id,
      file
    });
  }

  const triggerFileInput = (e: React.MouseEvent, activityId: string) => {
    e.stopPropagation();
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const updateActivity = (update: any) => {
    if (!activity.eventId || !activity.programId || !activity.id) {
      return
    };
    updateActivityMutation.mutate({
      eventId: activity.eventId,
      dayId: activity.programId,
      activityId: activity.id,
      payload: update
    });
  };

  const handleStartTimeChange = (isoTimeString: string, activity2: any) => {
    const selectedDate = moment.utc(isoTimeString);
    if (selectedDate.isBefore(moment.utc())) {
      toast?.showErrorToast('Start time cannot be in the past');
      return;
    }

    // const endDateTime = moment.utc(activity.endDateTime);
    if (selectedDate.isSameOrAfter(activity.endDateTime)) {
      let newEndDateTime = selectedDate.clone().add(1, 'hour');
      if (newEndDateTime.isAfter(moment.utc(selectedDate).endOf('day'))) {
        newEndDateTime = selectedDate.clone().set({
          hour: 23,
          minute: 59,
          second: 59,
          millisecond: 0
        });
      }
      updateActivity({ endIsoTime: newEndDateTime.toISOString(), startIsoTime: selectedDate.toISOString() });
      setEndTime(newEndDateTime.toISOString());
    } else {
      updateActivity({ startIsoTime: selectedDate.toISOString() });
    }
  };

  const handleEndTimeChange = (isoTimeString: string) => {

    const selectedDate = moment.utc(isoTimeString);

    if (selectedDate.isSameOrBefore(moment.utc())) {
      toast?.showErrorToast('End time cannot be in the past');
      setEndTime(activityEndTime);
      return;
    }

    const startDateTime = moment.utc(activity.startDateTime);
    if (selectedDate.isBefore(startDateTime)) {
      let newStartDateTime = selectedDate.clone().subtract(1, 'hour');
      if (newStartDateTime.isBefore(moment.utc(selectedDate).startOf('day'))) {
        newStartDateTime = selectedDate.clone().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        });
      }
      updateActivity({ startIsoTime: newStartDateTime.toISOString(), endIsoTime: selectedDate.toISOString() });
      setStartTime(newStartDateTime.toISOString());
    } else {
      updateActivity({ endIsoTime: selectedDate.toISOString() });
    }

  };

  const emptyLocationString = 'Click To Add Location Url';

  return (
    <div className="flex flex-1 overflow-hidden activity-details">
      <input
        type="file"
        ref={fileInputRef}
        className="hidden"
        accept="image/*"
        onChange={(e) => {
          handleImageUpload(e)
        }} />
      <div className="flex flex-1 py-10"
        onDragOver={(e) => {
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          // setDragging(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          // setDragging(false);
        }}
        onDrop={(e) => {
          e.stopPropagation();
          e.preventDefault();
          if (mode !== EventViewMode.MANAGE) {
            return;
          }
          // setDragging(false);
          handleDragUpload(e as any);
        }}>
        {activity ? (
          <div className={`flex flex-1 flex-col relative overflow-hidden`}>
            <div className='flex-1 overflow-y-auto overflow-y-auto custom-scroll px-4 '>
              {
                <div className={`relative flex w-full object-cover border border-brand-fadegray rounded-lg
                 aspect-video mb-4 justify-center bg-gradient-to-t from-brand-darkgray/0 via-brand-lightgray/30 to-brand-darkgray/0`}>

                  {
                    activity.image && <img
                      src={activity.image}
                      className="absolute w-full h-full object-cover rounded-lg " /> || <>
                      {
                        mode === EventViewMode.MANAGE && <Camera className={`w-6 mx-auto h-6 drop-shadow-contrast-black self-center cursor-pointer z-40`}
                          onClick={(e) => {
                            e.stopPropagation();
                            triggerFileInput(e, activity.id)
                          }} />
                      }
                    </>
                  }
                  {(isHost && mode === EventViewMode.MANAGE) && <div className="absolute right-0 bottom-0 flex flex-1 justify-end items-end z-50">
                    <button
                      onClick={() => {
                        if (window.confirm('Are you sure you want to delete this activity?')) {
                          deleteActivityMutation.mutate({
                            eventId: activity.eventId,
                            dayId: activity.programId,
                            activityId: activity.id
                          });
                        }
                      }}
                      className="bg-brand-darkgray/50 text-brand-danger text-sm p-2 rounded m-1"
                      title='Delete' >
                      <Trash2 size={18} />
                    </button>
                  </div>}
                </div>
              }
              <h3
                ref={activityNameRef}
                contentEditable={isHost && mode === EventViewMode.MANAGE}
                suppressContentEditableWarning={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.currentTarget.blur();
                  }
                }}
                onBlur={(e) => {
                  if (mode !== EventViewMode.MANAGE) {
                    return;
                  }
                  const content = e.target.innerText.trim();

                  if (content && content !== activity?.name) {
                    updateActivity({ name: content })
                  } else {
                    // toast?.showErrorToast('Invalid Update');
                    e.target.innerText = activity.name;
                  }
                }}
                className={`text-2xl font-semibold text-brand-lightgray mb-6 text-left ${mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
                {activity?.name}
              </h3>

              <div
                ref={subtitleRef}
                contentEditable={isHost && mode === EventViewMode.MANAGE}
                suppressContentEditableWarning={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.currentTarget.blur();
                  }
                }}
                onBlur={(e) => {
                  if (mode !== EventViewMode.MANAGE) {
                    return;
                  }
                  const content = e.target.innerText.trim();
                  if (content && content !== activity?.subtitle) {
                    updateActivity({ subtitle: content })
                  } else {
                    // toast?.showErrorToast('Invalid Update');
                    e.target.innerText = activity?.subtitle;
                  }
                }}
                className={`text-lg  custom-text-shadow mb-4 font-semibold text-brand-lightgray
                   ${mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
                <span>{activity?.subtitle ? activity?.subtitle : ''}</span>
              </div>

              <div className="grid grid-cols-1 sm:flex flex-row py-2 rounded-md text-brand-lightgray items-center gap-4 ">
                {/* <Calendar className="countdown font-mono w-5 h-5" /> */}
                <div className="flex flex-1 flex-row">
                  <label className="flex flex-1 mx-auto items-center justify-start text-sm font-medium text-brand-lightgray">
                    <Calendar className="countdown font-mono w-5 h-5 mr-6" /> Start
                  </label>
                  <DateTimePicker
                    label=""
                    value={startTime}
                    onChange={(value: any) => {
                      handleStartTimeChange(value, activity);
                    }}
                    minDate={moment().toISOString()} // Optional: restrict to future dates
                    maxDate={moment().add(1, 'year').toISOString()} // Optional: restrict to within next year
                    position='top'
                    mode='time'
                    disabled={!isHost || mode !== EventViewMode.MANAGE}
                  />
                </div>
                <div className="divider divider-horizontal hidden sm:flex"></div>
                <div className="flex flex-1 flex-row">
                  <label className="flex flex-1 mx-auto items-center justify-start text-sm font-medium text-brand-lightgray">
                    <Calendar className="countdown font-mono w-5 h-5 mr-6" /> End
                  </label>
                  <DateTimePicker
                    label=""
                    value={endTime}
                    onChange={handleEndTimeChange}
                    minDate={moment().toISOString()} // Optional: restrict to future dates
                    maxDate={moment().add(1, 'year').toISOString()} // Optional: restrict to within next year
                    position='top'
                    mode='time'
                    disabled={!isHost || mode !== EventViewMode.MANAGE}
                  />
                </div>
              </div>


              <div className="flex items-center gap-2 text-brand-lightgray">
                <div className="flex flex-row py-2 rounded-md text-brand-lightgray items-center gap-4">
                  <Clock className="countdown font-mono w-5 h-5 mr-2" />
                  <span className="countdown font-mono font-bold mt-1">
                    {getFormattedDuration(activity.startDateTime, activity.endDateTime)}
                  </span>
                </div>
              </div>

              {
                ((isHost && mode === EventViewMode.MANAGE) || activity?.location) &&

                <div className="flex flex-1 flex-row py-2 rounded-md text-brand-lightgray items-center gap-4 mt-2">


                  <MapPin className={`flex relative self-start mt-3.5 countdown font-mono ${activity.location ? 'cursor-pointer' : ''} w-5 h-5 mr-2`}
                    onClick={() => {
                      const link = activity?.location?.googleMapsUri || activity.location;
                      if (link && typeof link === 'string') {
                        window.open(link, '_blank')
                      }
                    }} />
                  <PlaceSearch
                    location={activity.location}
                    onPlaceSelect={(place: any) => {
                      updateActivity({ location: place });
                    }}
                    enabled={isHost && mode === EventViewMode.MANAGE}
                  />
              
                </div>
              }
              <pre
                ref={descriptionRef}
                contentEditable={isHost && mode === EventViewMode.MANAGE}
                suppressContentEditableWarning={true}
                onKeyDown={(e) => {
                  if (e.key === 'Enter' && !e.shiftKey) {
                    e.preventDefault();
                    e.currentTarget.blur();
                  }
                }}
                onBlur={(e) => {
                  if (mode !== EventViewMode.MANAGE) {
                    return;
                  }
                  const content = e.target.innerText.trim();

                  if (content && content !== activity?.description) {
                    updateActivity({ description: content })
                  } else {
                    // toast?.showErrorToast('Invalid Update 3');
                    e.target.innerText = activity.description;
                  }
                }}
                className={`text-sm font-sans whitespace-pre-line text-brand-lightgray my-6 ${mode === EventViewMode.MANAGE ? 'cursor-text' : 'cursor-default'}`}>
                {activity.description}
              </pre>
            </div>
          </div>
        ) : (
          <p className="text-sm text-brand-lightgray self-center mx-auto">Select an activity to see details</p>
        )}
      </div>
    </div>
  );
};

export default ActivityDetails;